<template>
  <v-container class="schedule-content">
      <v-spacer></v-spacer>
      <v-col md="6" offset-md="9" class="button-export">
        <button @click="handleClick" class="btnImport">Exportar Lojista</button>
      </v-col>
    <v-row>
      <v-col md="12" class="data-table">
        <v-data-table
          hide-default-footer
          :headers="headers"
          :items="dataTableItems"
          :items-per-page="10"
          :mobile-breakpoint="600"
          class="dataTableContent"
        ></v-data-table>
      </v-col>
    </v-row>
    <v-row class="pagination">
      <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
      <v-col class="colPagination" md="8" cols="12">
        <Pagination :pages="totalPages" :functionEmit="'paginationSellerSchedule'" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';
import service from '../../../service';
import Pagination from '../../../components/Pagination.vue';

export default {
  components: {
    Pagination,
  },
  data: () => ({
    request: 'R$ 0,00',
    date: 'Janeiro 2050',
    service,
    headers: [
      {
        text: 'Lojista',
        align: 'center',
        filterable: false,
        value: 'name',
      },
      {
        text: 'CNPJ',
        align: 'center',
        filterable: false,
        value: 'document',
      },
      {
        text: 'Saldo',
        align: 'center',
        filterable: false,
        value: 'balance',
      },
    ],
    dataTableItems: [],
  }),
  methods: {
    ...mapActions({
      setSchedule: 'Schedule/setSchedule',
      setExport: 'Schedule/setExport',
    }),
    forceFileDownload() {
      const url = window.URL.createObjectURL(new Blob([this.getExport]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${this.getFileName}.csv`);
      document.body.appendChild(link);
      link.click();
    },
    async handleClick() {
      await this.setExport();
      this.forceFileDownload();
    },
  },
  computed: {
    ...mapGetters({
      getSchedule: 'Schedule/getSchedule',
      getTotalPages: 'Schedule/getTotalPages',
      getExport: 'Schedule/getExport',
      getFileName: 'Schedule/getFileName',
    }),
    prepareData() {
      return this.getSchedule.map(el => ({
        name: el.name,
        document: this.service.maskToDocument(el.document),
        balance: service.formatCurrency(el.balance),
      }));
    },
    totalPages() {
      return parseInt(this.getTotalPages, 10);
    },
  },
  async created() {
    await this.setSchedule({ perPage: 10 });
    this.dataTableItems = this.prepareData;

    this.$bus.$on('paginationSellerSchedule', async (payload) => {
      await this.setSchedule({ page: payload, perPage: 10 });
      this.dataTableItems = this.prepareData;
    });
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/supplier/sellerSchedule.scss';
</style>
